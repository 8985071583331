var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_vm._v(" Daftar "),_c('b',[_vm._v("Departemen")]),_vm._v(" yang ada di Klinik Hayandra ")]),(_vm.btn)?_c('b-button',{attrs:{"squared":"","variant":"success"},on:{"click":function($event){return _vm.$router.push('/masters/departemens/add')}}},[_vm._v("Tambah")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"row justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama Departemen"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('div',[_c('b-table',{staticClass:"mt-3",style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '15%' : '' })})})}},{key:"cell(is_satusehat_upload)",fn:function(data){return [_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(data.item.is_satusehat_upload == 1 && data.item.is_need_sync == 0 ? "Terkirim" : ""))]),(
                    data.item.is_need_sync == 1 &&
                    data.item.is_satusehat_upload == 1
                  )?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Perlu Singkronisasi")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
                      path: '/masters/departemens/detail/' + data.item.id,
                    })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(_vm.btn)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Ubah"},on:{"click":function($event){return _vm.$router.push({
                      path: '/masters/departemens/edit/' + data.item.id,
                    })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(_vm.btn)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger mr-1",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e(),(_vm.btn)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-success btn-satusehat",class:data.item.is_need_sync == 1 ? 'pr-0' : '',attrs:{"size":"sm","title":"Sinkronisasi Satu Sehat"},on:{"click":function($event){return _vm.syncSatuSehat(data.item)}}},[_c('h6',[_c('i',{staticClass:"fas fa-sync px-0"}),_vm._v(" "),_c('sup',[(data.item.is_need_sync == 1)?_c('i',{staticClass:"fas fa-circle text-danger fa-xs px-0"}):_vm._e()])])]):_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }